import React from 'react';
import { withFederation } from '@unisporkal/federation';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { lazyComponent } from '@unisporkal/react-lazy-component';
import Providers from '../Providers';
import { APP_NAME, COMPONENTS } from '../constants';

const Component = lazyComponent(
  (props) => import(`./components/${props.siteName}/Footer`)
);

const Footer = ({ layout, locale, siteName }) => (
  <Providers
    siteName={siteName}
    locale={locale}
  >
    <Component
      layout={layout}
      siteName={siteName}
    />
    <ReactQueryDevtools
      initialIsOpen
      position="bottom-right"
    />
  </Providers>
);

export default withFederation(APP_NAME, COMPONENTS.FOOTER, Footer);
